<template>
  <div class="about">
    <div class="demo-app-content">
      <h1>Проверка поиска</h1>
      <ui-form class="conditions-form" nowrap action-align="center">
        <template #default="{ actionClass }">
          <ui-grid>
            <ui-grid-cell columns="12">
              <ui-form-field :class="['search-field', 'required']">
                <label>BASE URL API</label>
                <ui-textfield
                  v-model="baseUrl"
                  class="search-field"
                ></ui-textfield>
              </ui-form-field>
            </ui-grid-cell>

            <ui-grid-cell columns="2">
              <ui-form-field :class="['search-field', 'required']">
                <label>User</label>
                <ui-textfield
                  v-model="userId"
                  class="search-field"
                  @keypress.enter="handleSearch"
                ></ui-textfield>
              </ui-form-field>
            </ui-grid-cell>

            <ui-grid-cell columns="6">
              <ui-form-field>
                <label>Опции:</label>
                <div>
                  <ui-form-field>
                    <ui-checkbox v-model="isArticles" input-id="checkbox-articles"></ui-checkbox>
                    <label :for="`checkbox-articles`">Искать по артикулу?</label>
                  </ui-form-field>
                  <ui-form-field>
                    <ui-checkbox v-model="isStocks" input-id="checkbox-stocks"></ui-checkbox>
                    <label :for="`checkbox-stocks`">Учитывать остатки?</label>
                  </ui-form-field>
                  <ui-form-field>
                    <ui-checkbox v-model="zoning" input-id="checkbox-zone"></ui-checkbox>
                    <label :for="`checkbox-zone`">Отсекать зону 4?</label>
                  </ui-form-field>
                  <ui-form-field>
                    <ui-checkbox v-model="withoutZone3" input-id="checkbox-zone"></ui-checkbox>
                    <label :for="`checkbox-zone`">Отсекать зону 3?</label>
                  </ui-form-field>
                </div>
              </ui-form-field>
            </ui-grid-cell>
            <ui-grid-cell columns="4">
            </ui-grid-cell>
            <ui-grid-cell columns="2">
              <ui-form-field class="search-field" style="margin-bottom: 20px;">
                <label>Size</label>
                <ui-textfield
                  v-model="size"
                  class="search-field"
                  @keypress.enter="handleSearch"
                ></ui-textfield>
              </ui-form-field>
              <ui-form-field class="search-field">
                <label>From</label>
                <ui-textfield
                  v-model="from"
                  class="search-field"
                  @keypress.enter="handleSearch"
                ></ui-textfield>
              </ui-form-field>
            </ui-grid-cell>
            <ui-grid-cell columns="8"></ui-grid-cell>
            <ui-grid-cell columns="4">
              <ui-alert v-if="zoning && cotractorIds.length < 1" state="error">Добавьте id-шники контрагентов</ui-alert>
              <ui-form-field :class="['search-field', 'required']" style="margin-bottom: 20px;">
                <label>Contractor</label>
                <ui-textfield
                  v-model="contractorField"
                  class="search-field"
                  @keypress.enter="handleAddContractor"
                ></ui-textfield>
                <ui-textfield-helper
                  id="my-text-field-helper-text"
                >
                  Для добавления нажмите enter
                </ui-textfield-helper>
              </ui-form-field>
              <ui-form-field :class="['search-field', 'required']">
                <label>Найти</label>
                <ui-textfield
                  v-model="keywords"
                  class="search-field"
                  @keypress.enter="handleSearch"
                ></ui-textfield>
              </ui-form-field>
            </ui-grid-cell>

            <ui-grid-cell columns="6">
              <ui-list>
                <ui-item v-for="i, index in cotractorIds" :key="index" class="contractor_item">
                  <ui-item-text-content>{{ i }}</ui-item-text-content>
                  <ui-button icon="delete" @click="deleteContractor(index)">Delete</ui-button>
                </ui-item>
              </ui-list>
            </ui-grid-cell>

            <ui-grid-cell columns="6">
            </ui-grid-cell>

          </ui-grid>
          <ui-form-field :class="actionClass">
            <ui-button raised @click="handleSearch">Search</ui-button>
            <ui-button outlined @click="reset">Reset</ui-button>
          </ui-form-field>
        </template>
      </ui-form>
      <ui-grid class="demo-grid">
        <ui-grid-cell class="demo-cell" columns="12">
          <ui-table :data="data" :thead="thead" :tbody="tbody" fullwidth></ui-table>
        </ui-grid-cell>
      </ui-grid>
    </div>
    <ui-snackbar
      v-model="openAlertZero"
      :timeout-ms="4000"
      :message="'В результате пусто'"
      :action-button-text="'close'"
      position="center"
    ></ui-snackbar>
  </div>
</template>

<script>
export default {
  name: 'SearchPage',
  data () {
    return {
      keywords: '',
      userId: '1',
      size: 100,
      from: 0,
      openAlertZero: false,
      cotractorIds: [2],
      contractorField: '',
      baseUrl: 'https://abom-lada-abom-es-api.zteam.dev-kodix.ru',
      zoning: true,
      withoutZone3: false,
      zoningError: true,
      isStocks: false,
      isArticles: false,
      coef: {
        coefficients: {
          title: 1,
          applicability: 1,
          brand: 1,
          crossFieldMatch: 50,
          brandRatingWeight: 1.4,
          original: 0.00015,
          hit: 0.0001,
          promotion: 0.00005,
          minimumScore: 10
        },
        features: {
          inverted: true,
          rescoreBrandRating: true,
          fuzzines: 1,
          rescoreSettings: {
            rescoreWaight: 1.4,
            initialQueryWaight: 0.7
          }
        }
      },
      source: [
      ],
      data: [],
      thead: [
        'ID',
        'Title',
        'Articule',
        'Brand',
        'РэйтингБ',
        '_score',
        'Зона',
        'Хит',
        'Акция',
        'Оригинал',
        'source id',
        'Type',
        'Применяемость',
        'Analogs'
      ],
      tbody: ['id', 'title', 'article', 'brandTitle', 'brandRating', '_score', 'zone', 'hit', 'promotion', 'original', 'sourceId', 'type', 'applicability', 'analogsIds']
    }
  },
  async mounted () {
    const result = await this.$api.getSettings(this.baseUrl)

    this.coef.coefficients = result.coefficients
    this.coef.features = result.features
  },
  methods: {
    reset () {
      this.cotractorIds.splice(0)
      this.contractorField = ''
      this.from = 0
      this.size = 100
      this.userId = '1'
      this.keywords = ''
      this.data.splice(0)
      this.isStocks = false
      this.isArticles = false
      this.zoning = true
    },
    async saveSettings () {
      const result = await this.$api.setSettings(this.baseUrl, {
        coefficients: this.coef.coefficients,
        features: this.coef.features
      })

      console.log(result)
    },
    handleAddContractor () {
      this.cotractorIds.push(this.contractorField)
      this.contractorField = ''
    },
    deleteContractor (index) {
      this.cotractorIds.splice(index, 1)
    },
    async handleSearch () {
      this.data.splice(0)
      if (!this.userId) return null
      if (!this.keywords) return null

      if (this.zoning && this.cotractorIds.length < 1) {
        this.data.splice(0)
        return null
      }

      const payload = {
        q: this.keywords,
        from: this.from,
        size: this.size,
        user: this.userId,
        baseUrl: this.baseUrl,
        zoning: this.zoning,
        salable: this.isStocks,
        withZone3: !this.withoutZone3,
        searchByArticles: this.isArticles
      }

      if (this.cotractorIds) payload.sourceIds = this.cotractorIds

      const result = await this.$api.search(payload)

      if (result.products.length === 0) this.openAlertZero = true
      this.data = result.products
        .map(e => ({
          ...e,
          analogsIds: e.analogs.map((e) => e.id),
          brandTitle: e.brand ? e.brand.title : '',
          brandRating: e.brand ? e.brand.rating : '',
          type: e.foundByText ? 'По тексту' : 'По артикулу'
        }))
    }
  }
}
</script>

<style lang="scss">
  .about {
    height: 100%;
    overflow: auto;
  }
  .contractor_item {
    justify-content: space-between;
  }
  .search-field {
    margin-left: 10px;
    width: 100%;
    label {
      min-width: 100px;
      text-align: left;
    }
  }
</style>
