<template>
  <div class="about">
    <h1>Проверка подсказок</h1>
    <ui-grid class="demo-grid">
      <ui-grid-cell columns="12">
        <ui-form-field :class="['search-field', 'required']">
          <label>BASE URL API</label>
          <ui-textfield
            v-model="baseUrl"
            class="search-field"
          ></ui-textfield>
        </ui-form-field>
      </ui-grid-cell>
      <ui-grid-cell class="demo-cell" columns="3" />
      <ui-grid-cell class="demo-cell" columns="6">
        <ui-autocomplete
          v-model="keywords"
          :source="source"
          :remote="true"
          fullwidth
          @update:modelValue="handlePrompt"
          placeholder="Try to type"
        ></ui-autocomplete>
      </ui-grid-cell>
      <ui-grid-cell class="demo-cell" columns="3" />
    </ui-grid>
    <ui-grid class="demo-grid">
      <ui-grid-cell class="demo-cell" columns="12">
        <ui-table :data="data" :thead="thead" :tbody="tbody" fullwidth></ui-table>
      </ui-grid-cell>
    </ui-grid>
  </div>
</template>

<script>
export default {
  name: 'PromptPage',
  data () {
    return {
      keywords: '',
      baseUrl: 'https://abom-lada-abom-es-api.zteam.dev-kodix.ru',
      source: [
      ],
      data: [],
      thead: [
        'Title',
        'Articule',
        'Brand',
        'Type'
      ],
      tbody: ['title', 'article', 'brand', 'type']
    }
  },
  methods: {
    async handlePrompt (q) {
      const prompt = await this.$api.getPrompt({ q, baseUrl: this.baseUrl })
      this.data = prompt.hintsByTitle.map(
        el => ({
          title: el.title,
          type: 'по тексту'
        })
      )
        .concat(
          prompt.hintsByArticle.map(el => ({
            title: el.title,
            brand: el.brand,
            article: el.article.text,
            type: 'по артикулу'
          }))
        )
        .concat(
          prompt.convertedHintsByTitle.map(el => ({
            title: el.title,
            type: 'инвертированная'
          }))
        )
    }
  }
}
</script>

<style lang="scss">
  .about {
    width: 100%;
  }
  .demo-content {
    width: 100%;
  }
  .search-field {
    margin-left: 10px;
    width: 100%;
    label {
      min-width: 100px;
      text-align: left;
    }
  }
</style>
