<template>
  <div class="about">
    <h1>Проверка поиска по id товара</h1>
      <ui-form class="conditions-form" nowrap action-align="center">
        <template #default="{ actionClass }">
          <ui-grid>

            <ui-grid-cell columns="12">
              <ui-form-field :class="['search-field', 'required']">
                <label>BASE URL API</label>
                <ui-textfield
                  v-model="baseUrl"
                  class="search-field"
                ></ui-textfield>
              </ui-form-field>
            </ui-grid-cell>

            <ui-grid-cell columns="4">
              <ui-form-field :class="['search-field', 'required']">
                <label>Найти</label>
                <ui-textfield
                  v-model="keywords"
                  class="search-field"
                  @keypress.enter="handleSearch"
                ></ui-textfield>
              </ui-form-field>
            </ui-grid-cell>

            <ui-grid-cell columns="6">
            </ui-grid-cell>

          </ui-grid>
          <ui-form-field :class="actionClass">
            <ui-button raised @click="handleSearch">Search</ui-button>
            <ui-button outlined @click="reset">Reset</ui-button>
          </ui-form-field>
        </template>
      </ui-form>
    <ui-grid class="demo-grid">
      <ui-grid-cell class="demo-cell" columns="12">
        <ui-table :data="data" :thead="thead" :tbody="tbody" fullwidth></ui-table>
      </ui-grid-cell>
    </ui-grid>
  </div>
</template>

<script>
export default {
  name: 'SearchPage',
  data () {
    return {
      keywords: '',
      userId: '1',
      cotractorIds: [],
      contractorField: '',
      baseUrl: 'https://abom-lada-abom-es-api.zteam.dev-kodix.ru',
      zoning: true,
      isStocks: false,
      source: [
      ],
      data: [],
      thead: [
        'ID',
        'Title',
        'Articule',
        'Brand',
        '_score',
        'РэйтингБ',
        'Зона',
        'Хит',
        'Акция',
        'Оригинал',
        'source id',
        'Analogs'
      ],
      tbody: ['id', 'title', 'article', 'brandTitle', '_score', 'brandRating', 'zone', 'hit', 'promotion', 'original', 'sourceId', 'analogsIds']
    }
  },
  methods: {
    reset () {
      this.cotractorIds.splice(0)
      this.contractorField = ''
      this.userId = '1'
      this.keywords = ''
      this.data.splice(0)
      this.isStocks = false
      this.zoning = true
    },
    handleAddContractor () {
      this.cotractorIds.push(this.contractorField)
      this.contractorField = ''
    },
    async handleSearch () {
      if (!this.keywords) return null

      const payload = {
        standardId: this.keywords,
        baseUrl: this.baseUrl
      }

      const result = await this.$api.findById(payload)

      this.data = result.products.map(e => ({
        ...e,
        analogsIds: e.analogs ? e.analogs.map(an => an.id) : [],
        brandTitle: e.brand ? e.brand.title : '',
        brandRating: e.brand ? e.brand.rating : ''
      }))
    }
  }
}
</script>

<style lang="scss">
  .search-field {
    margin-left: 10px;
    width: 100%;
    label {
      min-width: 100px;
      text-align: left;
    }
  }
  .about {
    height: 100%;
    overflow: auto;
    width: 100%;
  }
  .demo-content {
    width: 100%;
  }
  .contractor_item {
    justify-content: space-between;
  }
</style>
